<script setup lang="ts">
definePageMeta({ middleware: 'marketing' });

useHead({
  titleTemplate: 'Pilot Car App - Find Drivers and Loads - 300,000 Monthly Alerts',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'The oversize load board on Pilot Car Loads shows all the active loads at a quick glance.',
    },
  ],
});

const { loadsPerMonth, alertsPerMonth, totalUsers } = storeToRefs(useMarketingStore());
</script>

<template>
  <div>
    <div class="bg-gray-50 pt-12 sm:pt-16">
      <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-4xl text-center">
          <h1 class="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10">
            Trusted by Pilot Car Drivers in the Oversize Industry
          </h1>
        </div>
      </div>
      <div class="mt-10 bg-white pb-12 sm:pb-16">
        <div class="relative">
          <div class="absolute inset-0 h-1/2 bg-gray-50" />
          <div class="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-4xl">
              <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Oversize loads posted <br><span class="text-sm">this month</span>
                  </dt>
                  <dd class="order-1 text-5xl font-extrabold leading-none text-teal-700">
                    {{ loadsPerMonth }}
                  </dd>
                </div>
                <div class="flex flex-col border-y border-gray-100 p-6 text-center sm:border-0 sm:border-x">
                  <dt
                    id="item-1"
                    class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500"
                  >
                    Load alerts sent <br><span class="text-sm">this month</span>
                  </dt>
                  <dd
                    class="order-1 text-5xl font-extrabold leading-none text-teal-700"
                    aria-describedby="item-1"
                  >
                    {{ alertsPerMonth }}
                  </dd>
                </div>
                <div class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Total users <br><span class="text-sm">registered</span>
                  </dt>
                  <dd class="order-1 text-5xl font-extrabold leading-none text-teal-700">
                    {{ totalUsers }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div class="lg:col-gap-8 mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:grid lg:grid-cols-3 lg:px-8 lg:py-24">
        <div>
          <h2 class="text-base font-semibold uppercase leading-6 tracking-wide text-teal-700">
            Pilot Car Load Board
          </h2>

          <p class="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
            All-in-one load app
          </p>

          <p class="mt-4 text-lg leading-7 text-gray-500">
            Features made specifically for pilot car drivers, pilot car companies, trucking companies and brokers.
          </p>

          <div class="flex flex-row items-center justify-center py-4">
            <img
              alt="Screenshot of iPhone App"
              src="~assets/img/apps/iphonex-loads.png"
              class="w-1/2"
            >
            <img
              alt="Screenshot of Android App"
              src="~assets/img/apps/android-alerts.png"
              class="w-1/2"
            >
          </div>

          <div class="flex flex-col items-center justify-center py-4 lg:flex-row">
            <nuxt-link
              href="https://apps.apple.com/us/app/pilot-car-loads/id1551499871"
              class="inline-block w-[200px] overflow-hidden rounded-[13px]"
              target="_blank"
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1612051200&h=b30685a1415c26e1cc7cd2a874c4d7c7"
                alt="Download on the App Store"
                class="w-[200px] rounded-[13px]"
              >
            </nuxt-link>

            <nuxt-link
              href="https://play.google.com/store/apps/details?id=focusstate.pilotcarloads&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                class="w-[250px]"
              >
            </nuxt-link>
          </div>
        </div>
        <div class="mt-12 lg:col-span-2">
          <dl class="sm:col-gap-6 sm:row-gap-10 lg:col-gap-8 space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-3 sm:space-y-0">
            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Nationwide Load Board
                </dt>
                <dd class="flex space-x-3 lg:py-0 lg:pb-4">
                  <span class="text-base leading-6 text-gray-500">Get access to our nationwide load board with new loads posted daily.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Real Time Load Alerts
                </dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Get unlimited load notifications sent right to your phone as soon as they are posted.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  30-Day Free Trial*
                </dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Pilot Car Drivers get 30 days to try out all the premium features for free. No credit card required.</span>
                </dd>
              </div>
            </div>

            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Sign up to post for free
                </dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Trucking companies and brokers can post loads for free, forever.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Apps for iPhone, iPad, and Android
                </dt>
                <dd class="flex space-x-3 lg:border-t-0 lg:py-0 lg:pb-4">
                  <span class="text-base leading-6 text-gray-500">All the same great features of the website are now in the app. Available for free on the <a
                    href="https://apps.apple.com/us/app/pilot-car-loads/id1551499871"
                    target="_blank"
                    class="underline"
                    rel="noopener noreferrer"
                  >Apple App Store</a> and <a
                    href="https://play.google.com/store/apps/details?id=focusstate.pilotcarloads"
                    target="_blank"
                    class="underline"
                    rel="noopener noreferrer"
                  >Google Play</a>.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <svg
                class="size-6 shrink-0 text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Pilot Car Directory
                </dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Pilot Car Companies can add their profile to our Pilot Car Directory. Companies can list their services, locations, certifications, and insurance so Trucking Companies can easily find you.</span>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <marketing-cta />
  </div>
</template>
